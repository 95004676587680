import * as universal_hooks from "/src/hooks.ts";

export { matchers } from "/.svelte-kit/generated/client/matchers.js?t=1743483063586";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js?t=1743491630092"),
	() => import("/.svelte-kit/generated/client/nodes/1.js?t=1743483063582"),
	() => import("/.svelte-kit/generated/client/nodes/2.js?t=1743483063583"),
	() => import("/.svelte-kit/generated/client/nodes/3.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/4.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/5.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/6.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/7.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/8.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/9.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/10.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/11.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/12.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/13.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/14.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/15.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/16.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/17.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/18.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/19.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/20.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/21.js?t=1743483063584"),
	() => import("/.svelte-kit/generated/client/nodes/22.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/23.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/24.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/25.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/26.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/27.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/28.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/29.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/30.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/31.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/32.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/33.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/34.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/35.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/36.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/37.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/38.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/39.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/40.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/41.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/42.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/43.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/44.js?t=1743483063585"),
	() => import("/.svelte-kit/generated/client/nodes/45.js?t=1743483063585")
];

export const server_loads = [0];

export const dictionary = {
		"/": [7],
		"/auth/api-key": [8,[2]],
		"/auth/login": [9,[2]],
		"/auth/signup": [10,[2]],
		"/auth/verify-2fa": [11,[2]],
		"/blocks": [12,[3]],
		"/blocks/auth": [13,[3]],
		"/blocks/editor": [14,[3]],
		"/blocks/editor/rich-text": [15,[3]],
		"/blocks/editor/templates/blog": [16,[3]],
		"/blocks/editor/templates/landing": [17,[3]],
		"/blocks/editor/templates/media": [18,[3]],
		"/blocks/paraglide": [19,[3]],
		"/blocks/pos": [20,[3]],
		"/blocks/pos/pages/customer": [21,[3]],
		"/blocks/pos/pages/menu-management": [22,[3]],
		"/blocks/pos/pages/order-management": [23,[3]],
		"/blocks/youtube-style": [24,[3]],
		"/blocks/youtube-style/editor": [25,[3]],
		"/blocks/youtube-style/pages/channel": [26,[3]],
		"/blocks/youtube-style/pages/home": [27,[3]],
		"/blocks/youtube-style/pages/video": [28,[3]],
		"/blog": [29,[4]],
		"/blog/[slug]": [30,[4]],
		"/docs": [31,[5]],
		"/docs/components/auth/form": [32,[5]],
		"/docs/components/auth/overview": [33,[5]],
		"/docs/components/auth/provider": [34,[5]],
		"/docs/components/auth/social-buttons": [35,[5]],
		"/docs/components/auth/two-factor": [36,[5]],
		"/docs/components/auth/user-avatar": [37,[5]],
		"/docs/components/box": [38,[5]],
		"/plugins": [39],
		"/studios": [40,[6]],
		"/studios/[id]": [41,[6]],
		"/test": [42],
		"/webhooks": [43],
		"/webhooks/deliveries": [44],
		"/workspace/activity": [45]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from "/.svelte-kit/generated/root.js?t=1743483063586";